import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import ContactBar from "../../components/ContactBar";
import SingleImage from "../../library/SingleImage";
import Layout from "../../components/Layout";

const GeburtAkupunkturPage = (props) => (
  <Layout location={props.location}>
    <div className="page offer">
      <Helmet>
        <title>Geburtsvorbereitung</title>
        <meta
          name="description"
          content="Geburtsvorbereitende Akupunktur kann sehr gut auf den Verlauf der bevorstehenden Geburt einwirken"
        />
        <meta property="og:title" content="Geburtsvorbereitung" />
        <meta
          property="og:description"
          content="Geburtsvorbereitende Akupunktur kann sehr gut auf den Verlauf der bevorstehenden Geburt einwirken"
        />
      </Helmet>
      <ContactBar className="large-screen" />
      <div className="container">
        <h1>Geburtsvorbereitung</h1>
        <SingleImage image={props.data.geburtakupunktur.fluid} />
        <p>
          Ab der 36 SSW kann man mit wöchentlicher Akupunktur sehr gut auf den
          Verlauf der bevorstehenden Geburt einwirken. Bänder und Sehnen werden
          gelockert, die Energie der Frau gestärkt, die Reifung des Muttermundes
          gefördert und somit die Eröffnungsphase der Geburt nachweislich
          verkürzt.
        </p>
        <p>
          Beim Übertragen des Kindes kann mit bestimmten Akupunkturpunkten die
          Wehentätigkeit gefördert und gestärkt werden.
        </p>
        <div className="cta">
          <p>Buchen Sie noch heute einen Termin!</p>
          <a
            className="button"
            href="https://www.coachfrog.ch/de/fachperson/paola-hoffmann/?bookingOpen=1"
            target="_blank"
            rel="noopener noreferrer"
          >
            Online buchen
          </a>
        </div>
      </div>
    </div>
  </Layout>
);

export default GeburtAkupunkturPage;

export const query = graphql`
  query GeburtAkupunkturImageQuery {
    geburtakupunktur: imageSharp(
      fluid: { originalName: { regex: "/geburt/" } }
    ) {
      fluid(maxHeight: 580, cropFocus: ENTROPY) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;
